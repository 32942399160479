import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/js/bootstrap.bundle.min.js";
import Appointments from "./pages/appointments";
import BookingSuccess from "./pages/booking-success";
import Contactus from "./pages/contactus/contactus";
import DoctorProfile from "./pages/doctorprofile";
import Home1 from "./pages/home/home";
import Booking from "./pages/booking/booking1";
import MyProfile from "./pages/myProfile";
import Login from "./components/Login";
import { getSession } from "./pages/services/SessionManager";
import Terms from "./pages/terms";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const sessionData = getSession();

    if (token && sessionData && sessionData.isAuthenticated) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="main-wrapper">
        <Routes>
          <Route path="/" element={<Home1 />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/booking-success" element={<BookingSuccess />} />
          <Route path="/about-us" element={<DoctorProfile />} />
          <Route
            path="/appointments"
            element={isLoggedIn ? <Appointments /> : <Login />}
          />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/profile" element={<MyProfile />} />

          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
