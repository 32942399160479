import React from 'react';
import { Link } from "react-router-dom";
import Footer from '../../components/footer';

import FeatherIcon from 'feather-icons-react';

const Contactus = (props) => {

	function handleSubmit(event) {
		event.preventDefault();

		const formData = new FormData(event.target);

		fetch('/api/contact', {
			method: 'POST',
			body: formData,
		})
			.then((response) => {
				if (response.ok) {
					console.log('Form submitted successfully');
				} else {
					console.error('Form submission failed');
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}


	return (
		<>
			<>
				{/* Breadcrumb */}
			<div className="breadcrumb-bar-two pt-3 pb-1 p-0 " style={{ minHeight: "100px"}}>
					<div className="container">
					<div className="row align-items-center inner-banner p-0">
							<div className="col-md-12 col-12 text-center">
								<h2 className="breadcrumb-title">Contact Alfred</h2>
								<nav aria-label="breadcrumb" className="page-breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item" aria-current="page">
											Contact Me
										</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</div>
				{/* /Breadcrumb */}
				{/* Contact Us */}
				<section className="contact-section">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-12">
								<div className="section-inner-header contact-inner-header">
									<h6>Get in touch</h6>
									<h2>Have Any Question?</h2>
								</div>
								<div className="card contact-card">
									<div className="card-body">
										<div className="contact-icon">
											<i >
												<FeatherIcon icon="map-pin"  style={{width:"44px",height:"44px"}}/>
											</i>
										</div>
										<div className="contact-details">
											<h4>Address</h4>
											<p> Upper Hill Road, Old Mutual Towers, 1st floor, Nairobi</p>
										</div>
									</div>
								</div>
								<div className="card contact-card">
									<div className="card-body">
										<div className="contact-icon">
											<i>
											<FeatherIcon icon="phone" style={{width:"44px",height:"44px"}}/>
											</i>
										</div>
										<div className="contact-details">
											<h4>Phone Number</h4>
											<p>  +254 722310584 </p>
										</div>
									</div>
								</div>
								<div className="card contact-card">
									<div className="card-body">
										<div className="contact-icon">
											<i>
											<FeatherIcon icon="mail" style={{width:"44px",height:"44px"}} />
											</i>
										</div>
										<div className="contact-details">
											<h4>Email Address</h4>
											<p>alfred.mathu@hisaafricainsurance.com</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 d-flex">
								<div className="card contact-form-card w-100">
									<div className="card-body">
										<form action="#" onSubmit={handleSubmit}>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<label>Name</label>
														<input
															type="text"
															className="form-control"
															placeholder="Enter Your Name"
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Email Address</label>
														<input
															type="text"
															className="form-control"
															placeholder="Enter Email Address"
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Phone Number</label>
														<input
															type="text"
															className="form-control"
															placeholder="Enter Phone Number"
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label>Services</label>
														<input
															type="text"
															className="form-control"
															placeholder="Enter Services"
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label>Message</label>
														<textarea
															className="form-control"
															placeholder="Enter your comments"
															defaultValue={""}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group form-group-btn mb-0">
														<button
															type="submit"
															className="btn btn-primary prime-btn"
														>
															Send Message
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* /Contact Us */}
				{/* Contact Map */}
				<section className="contact-map d-flex">
					<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3376.025929941284!2d36.81830202490242!3d-1.2996440093390118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10e400e852b7%3A0xae4857eea7d6651!2sOld%20Mutual%20Tower!5e0!3m2!1sen!2ske!4v1691819420740!5m2!1sen!2ske"
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					/>
				</section>
				{/* /Contact Map */}
			</>
			<Footer {...props} />
		</>


	)
}


export default Contactus;