import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from "../../components/footer"

const Terms = (props) => {
    return (
        <>
            {/* Breadcrumb */}
            <div className="breadcrumb-bar-two pt-3 pb-1 p-0 " style={{ minHeight: "100px" }}>

                <div className="container">
                    <div className="row align-items-center inner-banner p-0">
                        <div className="col-md-12 col-12 text-center">
                            <h2 className="breadcrumb-title">Terms &amp; Condition</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        Terms &amp; Condition
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Breadcrumb */}
            {/* Terms */}
            <section className="terms-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="terms-content">
                                <div className="terms-text">
                                    <p><h3>
                                        <strong>Terms and Conditions for Booking an Appointment with The Financial Doctor.</strong>
                                    </h3>
                                    </p>
                                    <p>
                                        These terms and conditions ("Terms") govern the booking of a free appointment with Alfred Mathu, who acts as an intermediary between Absa Life Assurance and Old Mutual, for the services related to savings, investments, retirement planning, critical illness insurance, life insurance, and health insurance (collectively, "Financial Services"). By booking an appointment with The Financial Doctor, you ("Client") agree to adhere to these Terms.
                                        {" "}
                                    </p>

                                </div>

                                <div className="terms-text terms-list">

                                    <h4>1. Appointment Booking</h4>

                                    1.1. To book a free appointment with The Financial Doctor, the Client must provide accurate and complete information, including contact details. <br />

                                    1.2. Appointments can be scheduled via phone, email, or our online booking system, subject to availability.

                                    <h4 className='pt-4'>2. Appointment Confirmation</h4>

                                    2.1. Once the appointment is booked, the Client will receive a confirmation email or call with the appointment details, including date, time, and location. <br />

                                    2.2. Clients are responsible for verifying the accuracy of the appointment details and notifying us promptly of any discrepancies.

                                    <h4 className='pt-4' >3. Cancellation and Rescheduling</h4>

                                    3.1. Clients must provide at least 24 hours' notice for appointment cancellations or rescheduling.<br />

                                    3.2. Failure to provide timely notice may result in a cancellation fee or forfeiture of the appointment.

                                    <h4 className='pt-4'> 4. The Financial Doctor's Services</h4>

                                    4.1. The Financial Doctor (Alfred Mathu) will provide Financial Services based on the Client's specific needs and goals, acting as an intermediary between Absa Life Assurance and Old Mutual.<br />

                                    4.2. The Client acknowledges that Financial Services are advisory in nature, and the ultimate decision-making responsibility for investments, insurance, and financial decisions rests with the Client.

                                    <h4 className='pt-4'>5. Fees and Payment</h4>

                                    5.1. The initial consultation with The Financial Doctor is provided free of charge.<br />

                                    5.2. Any subsequent services or fees related to products recommended by The Financial Doctor will be discussed and agreed upon during the initial consultation.

                                    <h4 className='pt-4'>6. Confidentiality</h4>

                                    6.1. All information shared during the appointment will be kept confidential, except where disclosure is required by law or with the Client's explicit consent.

                                    <h4 className='pt-4'>7. Limitation of Liability</h4>

                                    7.1. While our financial advisor will provide professional guidance, we do not guarantee the success or profitability of any investment or financial decision.<br />

                                    7.2. The Client acknowledges that all investments carry risks, and past performance is not indicative of future results.

                                    <h4 className='pt-4'> 8. Termination of Services</h4>

                                    8.1. We reserve the right to terminate Financial Services at any time if the Client engages in illegal or unethical activities or breaches these Terms.

                                    <h4 className='pt-4'>9. Changes to Terms</h4>

                                    9.1. We may update these Terms from time to time, and any changes will be communicated to the Client in writing.

                                    <h4 className='pt-4'> 10. Contact Information</h4>

                                    10.1. For inquiries or assistance related to these Terms or booking an appointment, please contact us at: <br />

                                    <strong className='pt-4'>0722-310-584- Alfred Mathu <br />
                                        Email: alfred.mathu@hisaafricainsurance.com</strong>

                                </div>
                                <div className="terms-text">

                                    <p>
                                        By booking an appointment with The Financial Doctor, the Client acknowledges having read, understood, and agreed to these Terms and Conditions. These Terms constitute a legally binding agreement between the Client and Hisa Africa Insurance Limited.

                                    </p>
                                </div>
                            </div>
                            <div className="terms-btn">
                                <Link to="#" className="btn btn-right-now">
                                    Not right now...
                                </Link>
                                <Link to="#" className="btn btn-primary prime-btn">
                                    I agree with terms
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /Terms */}


            <Footer {...props} />
        </>
    );
}

export default Terms;