import React from 'react';
import { Link } from 'react-router-dom';
// import SimpleReactLightbox from 'simple-react-lightbox';
import MyComponent from './mycomponent';
// import { doctorthumb02 } from '../../imagepath';

const Pagecontent = () => {
  return (
    <>
    <div>
        <div className="card">
  
  </div>
</div>

  </>
  )
}

export default Pagecontent